import { getField, updateField } from 'vuex-map-fields'
import { prepareUrl, uri } from '../api/uri'

export const initState = {
  list: []
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async assign ({ commit, dispatch }, { taskId }) {
    commit('flushErrors', null, { root: true })
    const url = `${prepareUrl(uri.task.assign, { id: taskId })}`
    // send message
    await this.$axios.$post(url)
      .then((resp) => {
        // console.log('Task assign', url, resp)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  async cancelAssignment ({ commit, dispatch }, { taskId }) {
    commit('flushErrors', null, { root: true })
    const url = `${prepareUrl(uri.task.cancelAssignment, { id: taskId })}`
    // send message
    await this.$axios.$post(url)
      .then((resp) => {
        // console.log('Task cancel assignment', url, resp)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  },
  async batchUpdate ({ commit, dispatch }, { taskId, status }) {
    commit('flushErrors', null, { root: true })
    const url = `${prepareUrl(uri.task.batch, { id: taskId })}`
    // send message
    await this.$axios.$post(url, { status })
      .then((resp) => {
        // console.log('Task cancel assignment', url, resp)
      })
      .catch((e) => {
        commit('setError', {
          title: e.message,
          message: e.response && e.response.data && e.response.data.message
        }, { root: true })
      })
  }
}
export const mutations = {
  updateField
}
export const getters = {
  getField
}
