import { getField, updateField } from 'vuex-map-fields'

export const initState = {
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  customer ({ state, commit, dispatch, rootGetters }, id) {
    const getByName = rootGetters['repos/getByName']

    const checkLists = {
      'customers.talks': i => i?.user_a?.user_id === id || i?.user_b?.user_id === id,
      'customers.reports': i => i?.from_user_id === id || i?.to_user_id === id,
      'customers.feedbacks': i => i?.receiver_id === id || i?.sender_id === id,
      'customers.notes': i => i?.author?.user_id === id || i?.user?.user_id === id,
      'affiliates.users': i => i?.user?.user_id === id,
      tasks: i => i?.user_id === id,
      talks: i => i?.user_a?.user_id === id || i?.user_b?.user_id === id,
      messages: i => i?.sender?.user_id === id || i?.receiver?.user_id === id,
      notes: i => i?.author?.user_id === id || i?.receiver?.user_id === id
    }

    Object.keys(checkLists).forEach((repo) => {
      const itemsList = getByName(`${repo}/index`)
      const inItemsList = itemsList?.data?.filter(checkLists[repo])
      if (itemsList && inItemsList.length > 0) {
        dispatch('repos/refreshList', { repo }, { root: true })
      }
    })

    const chatList = rootGetters['chat/list']
    const inChatList = chatList?.filter(i => i?.talk?.user?.user_id === id)
    if (chatList && inChatList.length > 0) {
      dispatch('chat/loadTalks', null, { root: true })
      inChatList.forEach((t) => {
        const chatOpened = rootGetters['chat/getChatOpen'](t.talkId)
        if (chatOpened) {
          dispatch('chat/update', { talkId: t.talkId }, { root: true })
        }
      })
    }
  }
}
export const mutations = {
  updateField
}
export const getters = {
  getField
}
