import { uri } from '../api/uri'

export const initState = {
  users: [],
  customersOnlineId: [],
  customersOnlineCount: 0
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  __clear ({ commit }) {
    commit('__clear')
  },
  initUsers ({ commit }, users) {
    commit('initUsers', users)
  },
  addUser ({ commit }, user) {
    commit('addUser', user)
  },
  deleteUser ({ commit }, user) {
    commit('deleteUser', user)
  },
  async selfReload ({ commit }) {
    const res = await this.$axios(uri.me)
    if (res.data && res.data.data) {
      this.$auth.setUser(res.data.data)
      this.$gates.setRoles(res.data.data.roles)
    }
  },
  setCustomersOnline ({ commit }, list = []) {
    commit('setCustomersOnline', list)
  },
  setCustomersOnlineCount ({ commit }, count = 0) {
    commit('setCustomersOnlineCount', count)
  }
}
export const mutations = {
  __clear (state) {
    Object.assign(state, initState)
  },
  initUsers (state, users) {
    state.users = users
  },
  addUser (state, user) {
    state.users.push(user)
  },
  deleteUser (state, user) {
    state.users = state.users.filter((e) => {
      return e.id !== user.id
    })
  },
  setCustomersOnline (state, online) {
    state.customersOnlineId = online
  },
  setCustomersOnlineCount (state, cnt) {
    state.customersOnlineCount = cnt
  }
}
export const getters = {
  users (state, getters, rootState) {
    if (rootState.auth.loggedIn) {
      const authUser = rootState.auth.user.userId
      return state.users.filter((e) => {
        return e.id !== authUser
      }) || []
    }
    return []
  },
  customersOnline (state) {
    return state.customersOnlineId
  },
  customersOnlineCount (state) {
    return state.customersOnlineCount
  }
}
