import { getField, updateField } from 'vuex-map-fields'
// import { uri } from '../api/uri'
import { responceCodeIcon, responceCodeVariant } from '../mixins/helpers'

export const initState = {
  ds: [],
  chain: [],
  errors: [],
  // locked: [],
  calls: []
}

export const state = () => {
  return {
    ...initState
  }
}

export const actions = {
  addCall ({ commit }, { repo, method, name, idx, ...args }) {
    commit('addCall', { repo, method, name, idx, ...args })
  },
  releaseCalls ({ dispatch, commit, state, rootState }) {
    if (state.calls?.length > 0) {
      const calls = JSON.parse(JSON.stringify(state.calls))
      commit('releaseCalls')
      try {
        this.$loadingManual().start()
        return Promise.all(calls.map(async args => await dispatch('call', args)))
          .finally(() => {
            this.$loadingManual().finish()
          })
      } catch (e) {
        // stop loading if error
        this.$loadingManual().finish()
      }
    }
  },
  call ({ commit, dispatch, state }, { repo, method, name, idx, ...args }) {
    const inputs = this.$prepareInputs({ repo, method, name })
    const repository = this[`$${inputs.repo}Repository`]
    try {
      return repository[inputs.method](args)
        .then((response) => {
          if (!['store', 'update', 'delete'].includes(method)) {
            commit('set', { name: inputs.name, repo: inputs.repo, method, idx, data: response, args })
          } else if (['store', 'update'].includes(method)) {
          // If data changed refresh related item datasets
            dispatch('refreshItem', { repo, data: response, args })
            // If data changed refresh related list datasets
            dispatch('refreshList', { repo })
            return response && response.data
          } else {
          // If data changed refresh related list datasets
            dispatch('refreshList', { repo })
          }
        })
        .catch((e) => {
          const errorData = {
            repository: inputs.repo,
            method: inputs.method,
            error: e
          }
          commit('error', errorData)
        })
    } catch (e) {
      const errorData = {
        repository: inputs.repo,
        method: inputs.method,
        error: e
      }
      commit('error', errorData)
    }
  },
  refreshList ({ commit, dispatch, state }, { repo }) {
    const has = state.ds.filter(i => i.name === `${repo}/index`)
    if (has.length) {
      has.forEach((i) => {
        const method = i.name.replace(new RegExp(`${repo}\\/`), '')
        dispatch('addCall', { repo, method, name: i.name, idx: i.idx, ...i.args })
      })
      return dispatch('releaseCalls')
    }
  },
  refreshItem ({ commit, dispatch, state }, { repo, data, args }) {
    const has = state.ds.filter(i => i.name === `${repo}/show` && i.args.id === args.id)
    if (has.length) {
      has.forEach((i) => {
        const method = i.name.replace(new RegExp(`${repo}\\/`), '')
        commit('set', { name: i.name, repo, method, idx: i.idx, data, args: i.args })
      })
    }
  },
  cloneFetched ({ commit }, { fromIdx, toIdx }) {
    commit('cloneFetched', { fromIdx, toIdx })
  },
  flush ({ commit }, { repo, method, name, id, idx }) {
    const inputs = this.$prepareInputs({ repo, method, name })
    commit('flush', { name: inputs.name, repo: inputs.repo, id, idx })
  },
  flushError ({ commit }) {
    commit('flushError')
  },
  dismissError ({ commit }, error) {
    commit('dismissError', error)
  },
  chainAdd ({ commit }, { key, repo }) {
    commit('chainAdd', { key, repo })
  },
  chainShow ({ commit }, key) {
    commit('chainShow', key)
  },
  chainHide ({ commit }, key) {
    commit('chainHide', key)
  },
  chainDel ({ commit }, key) {
    commit('chainDel', key)
  },
  remoteUpdate ({ commit, dispatch, state, rootState }, { repo, data }) {
    const reposToUpdate = state.ds.filter((i) => {
      return i.repo === repo && (i.name === `${repo}/index` || i.name === `${repo}/show`)
    })
    const calls = []
    for (const ds of reposToUpdate) {
      const method = ds.name.replace(new RegExp(`${repo}\\/`), '')
      if (method === 'index') {
        const items = ds.data.data
        const exists = items.find(i => i.id === (data.data && data.data.id) || data.id)
        if (exists || items.length === 0) {
          calls.push(['refreshList', { repo }])
        }
      } else if (method === 'show') {
        calls.push(['call', { repo, method, name: ds.name, idx: ds.idx, ...ds.args }])
      }
    }
    return Promise.all(calls.map(async args => await dispatch(...args)))
  },
  // lockUpdate ({ commit, state }, lockList) {
  //   if (Array.isArray(lockList)) {
  //     commit('lockUpdate', lockList)
  //   }
  // },
  // lockItem ({ commit, dispatch }, { repo, id, by, email }) {
  //   commit('lockItem', { repo, id, by, email })
  // },
  // unlockItem ({ commit, dispatch }, { repo, id, by }) {
  //   commit('unlockItem', { repo, id, by })
  // },
  flushAll ({ commit }) {
    commit('flushAll')
  },
  warning ({ commit }, { repository, message }) {
    commit('warning', { repository, message })
  }
}

export const mutations = {
  updateField,
  set (state, { name, repo, method, idx, data, args }) {
    // console.log('SET', { name, repo, method, idx, data, args })
    const indexes = []
    if (idx && (method !== 'show' && method !== 'edit')) {
      indexes.push(state.ds.findIndex(i => i.name === name && i.idx === idx))
    } else if (method === 'show' || method === 'edit') {
      state.ds.forEach((item, itemIdx) => {
        if (item.name === name && item.args.id === args.id) {
          indexes.push(itemIdx)
        }
      })
    } else {
      state.ds.forEach((item, itemIdx) => {
        if (item.name === name) {
          indexes.push(itemIdx)
        }
      })
    }
    // upgrade existing datasets
    for (const itemIdx of indexes) {
      state.ds[itemIdx].data = data
      state.ds[itemIdx].args = args
    }
    // Add or Update current
    const itemIdx = idx
      ? state.ds.findIndex(i => i.name === name && i.idx === idx)
      : state.ds.findIndex(i => i.name === name)
    if (itemIdx >= 0) {
      state.ds[itemIdx].data = data
      state.ds[itemIdx].args = args
    } else {
      state.ds.push({ name, repo, idx, data, args })
    }
  },
  cloneFetched (state, { fromIdx, toIdx }) {
    state.ds.filter(i => i.idx === fromIdx).forEach((i) => {
      state.ds.push({
        name: i.name,
        repo: i.repo,
        idx: toIdx,
        data: i.data,
        args: i.args
      })
    })
  },
  flush (state, { name, repo, idx }) {
    if (idx) {
      state.ds = state.ds.filter(i => !(i.name === name && i.repo === repo && i.idx === idx))
    } else {
      state.ds = state.ds.filter(i => !(i.name === name && i.repo === repo))
    }
  },
  error (state, data) {
    const e = data.error
    if (e.response) {
      // console.log(data)
      const code = e.response.status
      if (e?.response?.data?.errors) {
        Object.values(e?.response?.data?.errors).forEach((errors) => {
          // state.errors = state.errors.concat(errors)
          const messages = [].concat(errors)
          messages.forEach((message) => {
            state.errors.push({
              code,
              variant: responceCodeVariant(code),
              icon: responceCodeIcon(code),
              repository: data.repository,
              method: data.method,
              message
            })
          })
        })
      } else if (e.response.data.message || e.message) {
        let message = e?.response?.data?.message || e.message
        if (process.env.NODE_ENV === 'production' && code === 500) {
          message = 'Server error, please try later.'
        }
        // state.errors.push(e.response.data.message)
        state.errors.push({
          code,
          variant: responceCodeVariant(code),
          icon: responceCodeIcon(code),
          repository: data.repository,
          method: data.method,
          message
        })
      } else {
        // state.errors.push('Error, please try later.')
        state.errors.push({
          code,
          variant: responceCodeVariant(code),
          icon: responceCodeIcon(code),
          repository: data.repository,
          method: data.method,
          message: 'Error, please try later.'
        })
      }
    } else {
      // state.errors.push('Error, please check internet connection.')
      state.errors.push({
        code: 0,
        variant: responceCodeVariant(0),
        icon: responceCodeIcon(0),
        repository: data.repository,
        method: data.method,
        message: 'Error, please check internet connection.'
      })
    }
  },
  warning (state, data) {
    state.errors.push({
      code: 423,
      variant: responceCodeVariant(423),
      icon: responceCodeIcon(423),
      repository: data.repository,
      method: 'edit',
      message: data.message
    })
  },
  dismissError (state, error) {
    const errors = state.errors.filter((e) => {
      return !(
        e.code === error.code &&
        e.repository === error.repository &&
        e.method === error.method &&
        e.message === error.message
      )
    })
    state.errors = errors
  },
  flushError (state) {
    state.errors = []
  },
  chainAdd (state, { key, repo }) {
    const idMatch = key.match(/\.([\d]*)$/)
    const nameMatch = key.match(/^([\w]*)\./)
    const id = idMatch.length > 1 ? parseInt(idMatch[1]) : -1
    const name = nameMatch.length > 1 ? nameMatch[1] : ''
    const path = `/${repo}${id > 0 ? `/${id}` : ''}`
    let prevPath = window.location.pathname
    if (path === prevPath) {
      prevPath = `/${repo}`
    }
    state.chain.push({
      idx: state.chain.length + 1,
      key,
      path,
      prevPath,
      id,
      name,
      value: false,
      repo
    })
    window.history.pushState({}, null, path)
  },
  chainShow (state, idx) {
    const item = state.chain.find(item => item.idx === idx)
    if (item) {
      item.value = true
    }
  },
  chainHide (state, idx) {
    const item = state.chain.find(item => item.idx === idx)
    if (item) {
      item.value = false
    }
  },
  chainDel (state, idx) {
    const chain = state.chain
    if (chain.length > 0) {
      const last = chain.splice(state.chain.length - 1, 1)
      if (last.length === 1 && last[0].idx === idx) {
        state.chain = chain
        if (last[0].prevPath !== `/${last[0].repo}`) {
          window.history.pushState({}, null, last[0].prevPath)
        } else {
          this.$router.push(last[0].prevPath)
        }
      }
    }
  },
  // lockUpdate (state, lockList) {
  //   state.locked = lockList
  // },
  // lockItem (state, { repo, id, by, email }) {
  //   if (state.locked.filter(i => i.repo === repo && i.id === id).length === 0) {
  //     state.locked.push({ repo, id, by, email })
  //   }
  // },
  // unlockItem (state, { repo, id, by }) {
  //   state.locked = state.locked.filter(i => !(i.repo === repo && i.id === id && i.by === by))
  // },
  addCall (state, obj) {
    const callIdx = state.calls.findIndex((call) => {
      // TODO: possible idx check needed
      return call.repo === obj?.repo && call.method === obj?.method
    })
    // replace call if already exists for current repos method
    if (callIdx >= 0) {
      const calls = state.calls
      calls[callIdx] = obj
      state.calls = calls
    } else {
      state.calls.push(obj)
    }
  },
  releaseCalls (state) {
    state.calls = []
  },
  flushAll (state) {
    state.ds = []
    state.chain = []
    state.errors = []
    // state.locked = []
    state.calls = []
  }
}

export const getters = {
  getField,
  getByName: state => (name, idx) => {
    const ds = idx
      ? state.ds.find(item => item.name === name && item.idx === idx)
      : state.ds.find(item => item.name === name)
    return ds && ds.data
  },
  getFetchedIdx: state => (repoName, id) => {
    const name = `${repoName}/show`
    const ds = id
      ? state.ds.filter(item => item.name === name && item.args.id === id).map(i => i.idx)
      : state.ds.filter(item => item.name === name).map(i => i.idx)
    // get max idx
    return ds && ds.length && ds.reduce(function (p, v) {
      return (p > v ? p : v)
    })
  },
  errors (state) {
    return state.errors
  },
  chain (state) {
    return state.chain
  },
  getFromChain: state => ({ key, itemId }) => {
    return state.chain.filter((item) => {
      if (key && itemId) {
        return item.itemId === itemId && item.key === key
      } else if (key) {
        return item.key === key
      }
      return false
    })
  },
  lastIdx (state) {
    const item = state.chain.length ? state.chain[state.chain.length - 1] : false
    return item ? item.idx : -1
  },
  callsCount (state) {
    return state.calls.length
  }
}
