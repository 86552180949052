import { getField, updateField } from 'vuex-map-fields'

export const initState = {
  portId: null,
  lastActivity: 0,
  instances: [],
  channels: [],
  isLocked: [],
  isLockSet: null,
  echoState: 'connected',
  isOnline: true,
  isWorkerLost: false,
  isWorkerUnavailable: false,
  worker: null,
  hash: null,
  type: null
}
export const state = () => {
  return {
    ...initState
  }
}

function postMessage (hash, port, message) {
  message.options.hash = hash
  port.postMessage(message)
}

export const actions = {
  init ({ commit, dispatch, state }) {
    let worker, type
    if (typeof SharedWorker !== 'undefined') {
      type = 'shared'
    } else if (typeof Worker !== 'undefined') {
      type = 'web'
    }
    commit('setType', type)
    // create shared worker
    if (!state.worker) {
      worker = this.$worker.createBoWorker()
    } else {
      worker = state.worker
    }

    if (worker) {
      if (type === 'shared') {
        worker.port.onmessage = (e) => {
          dispatch('receiveMessage', JSON.parse(e.data) || e.data)
        }
      } else if (type === 'web') {
        worker.onmessage = (e) => {
          dispatch('receiveMessage', JSON.parse(e.data) || e.data)
        }
      }
      commit('setWorker', worker)

      // watch user auth
      this.$auth.$storage.watchState('loggedIn', (isLoggedIn) => {
        if (isLoggedIn) {
          dispatch('config', { portId: state.portId, isInitialised: false })
        } else {
          dispatch('leave')
        }
      })
    } else {
      this.$error('[INIT][WORKER_FAIL]')
    }
  },
  unavailable ({ commit }) {
    commit('setUnavailable')
  },
  lost ({ commit, dispatch }, value) {
    commit('setLost', value)
    if (!value && this.$config.recordLocks) {
      dispatch('sendMessage', {
        cmd: 'lock',
        options: {
          action: 'getLocked',
          value: {}
        }
      })
    }
  },
  hashUpdate ({ commit }, hash) {
    commit('setHash', hash)
  },
  die ({ commit, state }) {
    this.$log('[DIE]')
    const port = state.type === 'shared' ? state.worker.port : state.worker
    if (port) {
      postMessage(state.hash, port, {
        cmd: 'die',
        options: {}
      })
      commit('setWorker', undefined)
    }
  },
  // establish socket connection
  config ({ state, rootState }, { portId, isInitialised }) {
    const isLoggedIn = rootState?.auth?.loggedIn
    this.$log('[CONFIG]', { portId, isInitialised, isLoggedIn })
    if (isLoggedIn && state.worker) {
      const options = !isInitialised
        ? {
            appHost: this.$config.appHost,
            auth: rootState.auth,
            echo: this.$config.echo,
            worker: this.$config.worker,
            portId
          }
        : { portId }
      const port = state.type === 'shared' ? state.worker.port : state.worker
      postMessage(state.hash, port, {
        cmd: 'config',
        options
      })
    }
  },
  leave ({ state }, channel) {
    if (state.worker) {
      this.$log('[LEAVE]', channel)
      const port = state.type === 'shared' ? state.worker.port : state.worker
      postMessage(state.hash, port, {
        cmd: 'leave',
        options: {
          channel
        }
      })
    }
  },
  sendMessage ({ state }, data) {
    if (state.worker) {
      if (data.cmd !== 'pong') {
        this.$log(`[TAB][SENT][${data.cmd.toUpperCase()}][${data?.options?.action?.toUpperCase()}]`, data?.options?.data || data?.options)
      }
      const port = state.type === 'shared' ? state.worker.port : state.worker
      postMessage(state.hash, port, {
        cmd: data.cmd,
        options: data.options || {}
      })
    }
  },
  receiveMessage ({ commit, dispatch, state, rootState }, data) {
    switch (data?.cmd) {
      case 'connected':
        commit('setPortId', data?.portId)
        this.$log('[INIT]', { workerId: state.hash, portId: state.portId })
        // If already logged in - config now
        if (rootState?.auth?.loggedIn) {
          dispatch('config', { portId: data?.portId, isInitialised: data?.isInitialised })
        }
        break
      case 'ping':
        if (this.$config.appDebug) {
          commit('setInstances', data?.options?.instances)
          commit('setChannels', data?.options?.channels)
        }
        if (this.$config.recordLocks) {
          commit('setIsLockSet', data?.options?.isLockSet)
          commit('setIsLocked', data?.options?.isLocked)
        }
        commit('setEchoState', data?.options?.echoState)
        commit('setOnlineState', data?.options?.isOnline)
        commit('setLastActivity', data?.options?.lastActivity)
        dispatch('sendMessage', {
          cmd: 'pong',
          options: {
            ts: data?.options?.ts,
            portId: data?.options?.portId
          }
        })
        if (data?.options?.workerHash !== state.hash) {
          this.$log('[RECEIVED][HASH HAS BEEN CHANGED]', data?.options?.workerHash, state.hash)
          dispatch('die')
        }
        break
      case 'echo':
        // parse pusher-worker responce
        this.$channelHandler(data)
        break
      case 'lock':
        // this is just stub, not used since worker locked records sync implemented
        break
      case 'upd':
        this.$log('[RECEIVED][UPD]', data)
        // parse sharedStore-worker responce
        this.$sharedUpdateHandler(data)
        break
      case 'die':
        setTimeout(() => {
          location.reload()
        }, 1000)
        break
      case 'state':
        // this is just stub, state will be wisible in page logs
        break
      default:
        this.$log('[RECEIVED][unknown]', data)
        break
    }
  }
}

export const mutations = {
  updateField,
  setWorker (state, value) {
    state.worker = value
  },
  setPortId (state, value) {
    state.portId = value
  },
  setInstances (state, value) {
    state.instances = value
  },
  setChannels (state, value) {
    state.channels = value
  },
  setIsLockSet (state, value) {
    if (this.$config.recordLocks) {
      state.isLockSet = value
    }
  },
  setIsLocked (state, value) {
    if (this.$config.recordLocks) {
      state.isLocked = value
    }
  },
  setEchoState (state, value) {
    state.echoState = value
  },
  setOnlineState (state, value) {
    state.isOnline = value
  },
  setHash (state, value) {
    state.hash = value
  },
  setUnavailable (state) {
    state.isWorkerUnavailable = true
  },
  setLost (state, value) {
    state.isWorkerLost = value
  },
  setLastActivity (state, value) {
    state.lastActivity = value
  },
  setType (state, value) {
    state.type = value
  }
}

export const getters = {
  getField,
  portId (state) {
    return state.portId
  },
  isConnected (state) {
    return state.echoState === 'connected'
  },
  isOnline (state) {
    return state.isOnline
  },
  checkWorkerLost (state) {
    return () => {
      const lostInterval = (new Date()).getTime() - state.lastActivity
      return lostInterval >= 10000
    }
  },
  isWorkerUnavailable (state) {
    return state.isWorkerUnavailable
  },
  isWorkerLost (state) {
    return state.isWorkerLost
  },
  totalLocked: (state) => {
    return state.isLocked.length
  },
  isLocked: (state, getters) => (repo, id) => {
    const isLocked = getters.isLockedBy(repo, id)
    return typeof isLocked !== 'undefined'
  },
  isLockedBy: (state, getters, rootState) => (repo, id) => {
    const userId = rootState?.auth?.user?.userId || 0
    const isLocked = state.isLocked?.find((i) => {
      return i.repo === repo &&
        parseInt(i.id) === parseInt(id) &&
        parseInt(i.by) !== parseInt(userId)
    })
    return isLocked
  },
  isLockedMy: (state, getters) => (repo, id) => {
    const isLocked = getters.isLockedByMe(repo, id)
    return typeof isLocked !== 'undefined'
  },
  isLockedByMe: (state, getters, rootState) => (repo, id) => {
    const userId = rootState?.auth?.user?.userId || 0
    const isLocked = state.isLocked?.find((i) => {
      return i.repo === repo &&
        parseInt(i.id) === parseInt(id) &&
        parseInt(i.by) === parseInt(userId)
    })
    return isLocked
  },
  instances: state => state.instances,
  channels: state => state.channels
}
